import React from "react"
import styled from "styled-components"
import VerticalSpacer from "./VerticalSpacer"
const StyledPageTitle = styled.div`
  /* ... */
`

export default function PageTitle({ title }) {
  return (
    <StyledPageTitle>
      <VerticalSpacer height="30px" />
      <h1 className="bigHeading gray1">{title}</h1>
      <VerticalSpacer height="10px" />
      <hr />
      <VerticalSpacer height="30px" />
    </StyledPageTitle>
  )
}
