import { Link } from "gatsby"
import React from "react"
import PageTitle from "../components/PageHeader"
import VerticalSpacer from "../components/VerticalSpacer"

export default function page404() {
  return (
    <div>
      <div className="container">
        <PageTitle title="Page Introuvable" />
        <p>
          Cette page n'existe pas.{" "}
          <Link className="bold" to="/">
            Retour à la page d'accueil
          </Link>
        </p>

        <VerticalSpacer height="96px" />
      </div>
    </div>
  )
}
